<template>
    <b-card>
        <h2>Detail Voucher</h2>
        <p>{{ this.name }}</p>

        <table v-if="this.v_type === 'serveral_use'">
            <tr>
                <td><strong>Name</strong></td>
                <td>{{ this.name }}</td>
            </tr>
            <tr>
                <td><strong>Code</strong></td>
                <td>{{ this.code }}</td>
            </tr>
            <tr>
              <td><strong>Voucher Type</strong></td>
              <td>Voucher IDR Multiple Redeem</td>
            </tr>
            <tr>
              <td><strong>User Type</strong></td>
              <td>{{ this.user_type }}</td>
            </tr>
            <tr>
                <td><strong>Start Date</strong></td>
                <td>{{ this.startFrom }}</td>
            </tr>
            <tr>
                <td><strong>End Date</strong></td>
                <td>{{ this.endTo }}</td>
            </tr>
            <tr>
                <td><strong>Maximum Usage</strong></td>
                <td>{{ this.max_attempt }}</td>
            </tr>
            <tr>
                <td><strong>Used Voucher</strong></td>
                <td>{{ this.max_redeem }} / {{ this.qty }} Users</td>
            </tr>
            <tr>
                <td><strong>Amount</strong></td>
                <td>Rp {{ this.amount }}</td>
            </tr>
            <tr>
              <td><strong>Partner/Organization</strong></td>
              <td>{{ this.usage_type }}</td>
            </tr>
            <tr>
                <td><strong>Approval</strong></td>
                <td v-if="this.isApproved == true"><span class="ui green label"><i class="fa fa-check"></i></span></td>
                <td v-else><span class="ui red label"><i class="fa fa-times"></i></span></td>
            </tr>
            <tr>
                <td><strong>Available</strong></td>
                <td v-if="this.isAvailable == true"><span class="ui green label"><i class="fa fa-check"></i></span></td>
                <td v-else><span class="ui red label"><i class="fa fa-times"></i></span></td>
            </tr>
            <tr v-if="this.v_type !== 'wallet'">
                <td><strong>Wallet Expire In</strong></td>
                <td>{{ this.walletExpire }}</td>
            </tr>
            <tr v-if="this.v_type !== 'wallet'">
                <td><strong>Wallet Expired Type</strong></td>
                <td>{{ this.expired_type }}</td>
            </tr>
            <tr>
                <td><strong>Organic</strong></td>
                <td v-if="this.is_organic == true"><span class="ui green label"><i class="fa fa-check"></i></span></td>
                <td v-else><span class="ui red label"><i class="fa fa-times"></i></span></td>
            </tr>
            <tr>
                <td><strong>Generated</strong></td>
                <td>{{ this.createdAt }} - {{ this.generatedBy }}</td>
            </tr>
            <tr>
                <td><strong>Usage History</strong></td>
                <td v-if="this.applyBy == null"> - </td>
                <td v-else>
                  <span v-for="(apply, index) in this.applyBy" :key="index">
                    - {{ apply }} <br>
                  </span>
                </td>
            </tr>            
        </table>
        <table v-else>
            <tr>
                <td><strong>Name</strong></td>
                <td>{{ this.name }}</td>
            </tr>
            <tr>
                <td><strong>Code</strong></td>
                <td>{{ this.code }}</td>
            </tr>
            <tr v-if="this.v_type === 'partnership'">
                <td><strong>Program Start</strong></td>
                <td>{{ this.program_start }}</td>
            </tr>
            <tr v-if="this.v_type === 'partnership'">
                <td><strong>Program End</strong></td>
                <td>{{ this.program_end }}</td>
            </tr>
            <tr>
                <td v-if="this.v_type !== 'wallet'"><strong>Start From</strong></td>
                <td v-else><strong>Program Start</strong></td>
                <td>{{ this.startFrom }}</td>
            </tr>
            <tr>
                <td v-if="this.v_type !== 'wallet'"><strong>End To</strong></td>
                <td v-else><strong>Program End</strong></td>
                <td>{{ this.endTo }}</td>
            </tr>
            <tr v-if="this.v_type !== 'wallet'">
                <td><strong>Quantity</strong></td>
                <td>{{ this.qty }}</td>
            </tr>
            <tr v-if="this.v_type !== 'wallet'">
                <td><strong>Maximum Usage</strong></td>
                <td>{{ this.max_attempt }}</td>
            </tr>
            <tr v-if="this.v_type !== 'wallet'">
                <td><strong>Used Voucher</strong></td>
                <td>{{ this.max_redeem }} / {{ this.qty }} Users</td>
            </tr>
            <tr>
                <td><strong>Amount</strong></td>
                <td v-if="this.type === 'gold'">{{ this.amount + " Gram" }}</td>
                <td v-else>IDR {{ this.amount }}</td>
            </tr>
            <tr v-if="this.v_type === 'partnership'">
              <td><strong>Type Usage</strong></td>
              <td v-if="this.behavior === 'single'">Single Nominal Redeem</td>
              <td v-else>Multiple Nominal Redeem</td>
            </tr>
            <tr>
              <td><strong>Type Voucher</strong></td>
              <td v-if="this.v_type === 'gold'">Gold Voucher</td>
              <td v-else-if="this.v_type === 'partnership'">Voucher Partnership (Single dan Multiple Redeem)</td>
              <td v-else-if="this.v_type === 'wallet'">Celengan (Saldo IDR Organik)</td>
              <td v-else>Voucher Celengan (Saldo IDR Anorganik</td>
            </tr>
            <tr>
                <td><strong>User Type</strong></td>
                <td>{{ this.user_type }}</td>
            </tr>
            <tr v-if="this.user_type === 'Register At'">
                <td><strong>Register Start</strong></td>
                <td v-if="this.register_start === null"><strong>-</strong></td>
                <td v-else>{{ this.register_start }}</td>
            </tr>
            <tr v-if="this.user_type === 'Register At'">
                <td><strong>Register End</strong></td>
                <td v-if="this.register_end === null"><strong>-</strong></td>
                <td v-else>{{ this.register_end }}</td>
            </tr>
            <tr v-if="this.user_type === 'Last Time Buy'">
                <td><strong>Last Time Buy - Days</strong></td>
                <td v-if="this.day === null"><strong>-</strong></td>
                <td v-else>{{ this.day }} Hari</td>
            </tr>
            <tr>
                <td><strong>Is Approved</strong></td>
                <td v-if="this.isApproved == true"><span class="ui green label"><i class="fa fa-check"></i></span></td>
                <td v-else><span class="ui red label"><i class="fa fa-times"></i></span></td>
            </tr>
            <tr>
                <td><strong>Is Available</strong></td>
                <td v-if="this.isAvailable == true"><span class="ui green label"><i class="fa fa-check"></i></span></td>
                <td v-else><span class="ui red label"><i class="fa fa-times"></i></span></td>
            </tr>
            <tr v-if="this.v_type !== 'wallet'">
                <td><strong>Wallet Expire In</strong></td>
                <td>{{ this.walletExpire }}</td>
            </tr>
            <tr v-if="this.v_type !== 'wallet'">
                <td><strong>Wallet Expired Type</strong></td>
                <td>{{ this.expired_type }}</td>
            </tr>
            <tr>
                <td><strong>Organic</strong></td>
                <td v-if="this.is_organic == true"><span class="ui green label"><i class="fa fa-check"></i></span></td>
                <td v-else><span class="ui red label"><i class="fa fa-times"></i></span></td>
            </tr>
            <tr v-if="this.v_type === 'partnership'">
                <td><strong>Voucher Partnership</strong></td>
                <td v-if="this.is_have_bucket_type == true"><span class="ui green label"><i class="fa fa-check"></i></span></td>
                <td v-else><span class="ui red label"><i class="fa fa-times"></i></span></td>
            </tr>
            <tr v-if="this.v_type === 'partnership'">
                <td><strong>Voucher Karantina</strong></td>
                <td v-if="this.is_quarantine == true"><span class="ui green label"><i class="fa fa-check"></i></span></td>
                <td v-else><span class="ui red label"><i class="fa fa-times"></i></span></td>
            </tr>
            <tr v-if="this.v_type === 'wallet'">
                <td><strong>Voucher Partnership</strong></td>
                <td><span class="ui red label"><i class="fa fa-times"></i></span> - Bukan tipe Voucher Partnership</td>
            </tr>
            <tr v-if="this.v_type === 'wallet'">
                <td><strong>Voucher Karantina</strong></td>
                <td><span class="ui red label"><i class="fa fa-times"></i></span> - Bukan tipe Voucher Karantina</td>
            </tr>
            <tr>
                <td><strong>Generated</strong></td>
                <td>{{ this.createdAt }} - {{ this.generatedBy }}</td>
            </tr>
            <tr>
                <td><strong>Usage History</strong></td>
                <td v-if="this.applyBy == null"> - </td>
                <td v-else>
                  <span v-for="(apply, index) in this.applyBy" :key="index">
                    - {{ apply }} <br>
                  </span>
                </td>
            </tr>
        </table>
    </b-card>
</template>

<script>

  export default {
    data() {
      return {
          name: '',
          code: '',
          startFrom: '',
          endTo: '',
          program_start: '',
          program_end: '',
          expired_type: '',
          is_organic: '',
          qty: '',
          amount: '',
          isApproved: '',
          isAvailable: '',
          walletExpire: '',
          createdAt: '',
          v_type: '',
          generatedBy: '',
          genereated: '',
          is_have_bucket_type: false,
          usageHistory: '',
          applyBy: '',
          max_usage: '',
          max_attempt: '',
          max_redeem: '',
          behavior: '',
          is_quarantine: false,
          user_type: '',
          register_start: '',
          register_end: '',
          day: '',
          usage_type: '',
          type: '',
            errors: {
            code: '',
            message: '',
            status: ''
            },
      }
    },
    created() {
        this.$http.get(`detail-voucher/` + this.$route.params.id)
        .then((result) => {
            this.name = result.data.meta.data.name;
            this.code = result.data.meta.data.code;
            this.startFrom = result.data.meta.data.start_from;
            this.endTo = result.data.meta.data.end_to;
            this.qty = result.data.meta.data.qty;
            this.amount = result.data.meta.data.amount;
            this.walletExpire = result.data.meta.data.wallet_expire_time;
            this.generatedBy = result.data.meta.data.generated_by.name;
            this.createdAt = result.data.meta.data.created_at;
            this.type = result.data.meta.data.voucher_type;
            this.behavior = result.data.meta.data.behavior;
            this.isApproved = result.data.meta.data.approve_status;
            this.isAvailable = result.data.meta.data.available_status;
            this.applyBy = result.data.meta.data.apply_by;
            this.program_start = result.data.meta.data.program_start;
            this.program_end = result.data.meta.data.program_end;
            this.is_organic = result.data.meta.data.is_organic;
            this.expired_type = result.data.meta.data.expired_type;
            this.v_type = result.data.meta.data.type;
            this.max_attempt = result.data.meta.data.max_attempt;
            this.is_have_bucket_type = result.data.meta.data.is_have_bucket_type;
            this.max_redeem = result.data.meta.data.max_redeem;
            this.is_quarantine = result.data.meta.data.is_quarantine;
            this.user_type = result.data.meta.data.user_type;
            this.register_start = result.data.meta.data.register_start;
            this.register_end = result.data.meta.data.register_end;
            this.day = result.data.meta.data.day
            this.usage_type = result.data.meta.data.type_usage
            // console.log(this.type);
        }).catch((error) => {
          if (error.response) {
            this.errors.code = error.response.status;
            this.errors.message = error.response.data.meta.message;
            this.errors.status = error.response.data.meta.code;
          }
        })
    }
  }
</script>

<style>
table {
  border-collapse: collapse;
  width: 100%;
}

td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
</style>
